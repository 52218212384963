@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0px;
  padding: 0px;
  background-color: #141a1e !important;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("./fonts/CabinetGrotesk-Variable.ttf") format("opentype");
}

.button {
  /* border-bottom: 3px solid rgba(0, 0, 0, 0.2); */
  display: inline-block;
  text-align: center;
  margin: 0 5px 0 0;
  position: relative;
}

.button:hover {
  position: relative;
  top: -2px;
  /* background: #0fbbad; */
}

.button:active {
  position: relative;
  top: 0px;
}

.button a {
  /* color: #fff; */
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  z-index: 3;
}

.button i {
  position: relative;
  top: 2px;
  display: inline-block;
  margin: 0 10px 0 0;
}

.button:before {
  position: absolute;
  width: auto;
  background: rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  right: 0px;
  bottom: -3px;
  content: " ";
  animation: slide 5s linear infinite;
  z-index: 2;
  animation-delay: 0s;
  border-radius: 2px 0 0 2px;
}

@keyframes slide {
  0% {
    right: 300px;
  }

  5% {
    right: 280px;
  }

  15% {
    right: 260px;
  }

  20% {
    right: 240px;
  }

  30% {
    right: 220px;
  }

  35% {
    right: 200px;
  }

  45% {
    right: 180px;
  }

  50% {
    right: 160px;
  }

  60% {
    right: 140px;
  }

  65% {
    right: 120px;
    border-radius: 2px 0 0 2px;
  }

  75% {
    right: 80px;
    opacity: 1;
    border-radius: 2px;
  }

  85% {
    right: 0px;
    opacity: 0;
  }

  90% {
    right: 0px;
    opacity: 0;
  }

  100% {
    right: 0px;
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

/* Apply animations to the image containers */

.animation-button {
  animation: slide 4s linear infinite;
}

.image-container-left {
  animation: slideUp 9s linear infinite;
}

.image-container-right {
  animation: slideDown 9s linear infinite;
}

.cabinet-grotesk {
  font-family: "Cabinet Grotesk";
}

.inter {
  font-family: "Inter";
}

.level-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.level-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.level-exit {
  opacity: 1;
  transform: translateY(0);
}

.level-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.slide-left {
  animation: slideLeft 10s linear infinite;
}

.slide-right {
  animation: slideRight 9s linear infinite;
}

.bg-blue {
  background-color: #141a1e;
}

.text-gray {
  color: #cbcbcb;
}

.bg-gray {
  background-color: #1c2124;
}

.bg-pink {
  background-color: #e7788d;
}

.bg-yellow {
  background-color: #efd165;
}

.text-yellow {
  color: #efd165;
}

.border-yellow {
  border-color: #efd165;
}

/* Apply border-radius to first row */
/* tbody tr:first-child {
  border-top-left-radius: 50px;
  border-top-right-radius: 10px;
} */
tbody {
  max-height: 500px; /* e.g. */
  overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
}

/* media for max-height */
@media (max-height: 500px) {
  tbody {
    max-height: 300px;
  }
}

/* media for max-height */
@media (max-height: 300px) {
  tbody {
    max-height: 100px;
  }
}

/* media for max-height */
@media (max-height: 100px) {
  tbody {
    max-height: 50px;
  }
}

/* media for max-height */
@media (min-height: 1200px) {
  tbody {
    max-height: 800px;
  }
}

.pagination {
  display: inline;
}
.pagination li {
  display: inline;
  text-align: center;
  color: white;
  border-radius: 4px;
  padding: 4px 6px;
}

.active {
  background-color: #efd165 !important;
  border-color: #efd165 !important;
  color: #000 !important;
}

.wide-toast {
  min-width: 350px;
  max-width: 350px;
}



.pulse-li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 0;
}

@keyframes pulse-animation {
0% {
  transform: translate(-50%, -50%) scale(0);
  opacity: .8
}
90% {
  opacity: 0;
  transform: translate(-50%, -50%) scale(3)
}
to {
  transform: translate(-50%, -50%) scale(0);
  opacity: 0
}
}

.pulse:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #f9e9a2;
  border-radius: 100%;
  opacity: .5;
  transform: translate(-50%,-50%) scale(3);
  animation: pulse-animation 2s infinite;
}

.pulse {
  position: relative;
  display: inline-block;
  vertical-align: 1px;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  background: #ffdd40;
  color: transparent;
  border-radius: 100%;
  flex: 0 0 auto;
  animation: opacity-animation 1s linear;
}

.pulse.green {
  background: #00e600;
}

.referral-count-container {
  margin: 20px 0;
  padding: 15px;
  background-color: #1c2124;
  border-radius: 8px;
  text-align: center;
}

.referral-count-container h3 {
  margin-bottom: 10px;
  color: #ffffff;
}

.referral-count-container p {
  font-size: 24px;
  font-weight: bold;
  color: rgba(239,209,101,1);
}

.wallet-address-container {
  margin: 20px 0;
  padding: 15px;
  background-color: #1c2124;
  border-radius: 8px;
  text-align: center;
}