
.level-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .level-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .level-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .level-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
  }